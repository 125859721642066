<template>
  <div class="layout-full bg-light py-3 py-lg-4">
    <div class="container overflow-hidden">
      <div class="row py-3 justify-content-center text-center" >
        <div class="col-lg-5 col-md-8">
          <a class="navbar-brand d-flex align-items-center" :href="absoluteUrl()">
            <img :src="absoluteUrl('/images/logos/logo-dark.svg')" width="150" class="navbar-brand-img mx-auto" alt="...">
          </a>
        </div>
      </div>
    </div>
    <div style="min-height: 70vh;padding:30px 0 20px 0;">
      <RouterView />
    </div>
    <div class="container overflow-hidden">
      <div class="row pt-4 justify-content-center text-center" >
        <div class="col-lg-5 col-md-8">
          <p class="text-muted  footer-copyright mb-0">
            ©{{ new Date().getFullYear()}} <a :href="absoluteUrl()" class="">{{$store.state.config.appName}}</a>. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods:{
    // absoluteUrl(){
    //   return ""
    // }
  }
}
</script>
